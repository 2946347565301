<template>
    <div class="footer" style="margin-top: 50px;color: hsla(0,0%,100%,.6);">
        <!-- <div class="ant-row">
            <div class="ant-col ant-col-8">
                <p class="title">Docs</p>
                <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#getting-started" target="_blank">Installation</a></p>
                <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#basic-configuration" target="_blank">Configuration</a></p>
                <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#Events" target="_blank">Events</a></p>
                <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#i18n" target="_blank">i18n</a></p>
            </div>
            <div class="ant-col ant-col-8">
            </div>
            <div class="ant-col ant-col-8">
                <p class="title">CHANNELS</p>
                <p><a href="https://github.com/core-player/vue-core-video-player" target="_blank">Github</a></p>
                <p><a href="https://github.com/core-player/vue-core-video-player/issues" target="_blank">FAQ</a></p>
                <p><a href="https://stackoverflow.com/questions/tagged/vue-core-video-player" target="_blank">Stackoverflow</a></p>
                <p><a href="hhttps://segmentfault.com/t/vue-core-video-player" target="_blank">Segmentfault</a></p>
                <p><a href="https://twitter.com/hashtag/vueCoreVideoPlayer?src=hashtag_click" target="_blank">Twitter</a></p>
                <p><a href="https://www.facebook.com/messages/t/3426412590762528" target="_blank">Facebook</a></p>
            </div>
        </div> -->
        <p>
            版权声明：如果来函说明本网站提供内容系本人或法人版权所有.本网站在核实后，有权先行撤除，以保护版权拥有者的权益
        </p>
        <!-- link链接 -->
        <div>
            <a href="">商务合作</a> |
            <a href="">问题反馈</a> |
            <a href="">求片专区</a> |
            <a>TV App下载</a> |
            <a>Android App下载</a> |
            <a>iOS App下载</a>
        </div>
        <!-- logo -->
        <div>
            <p>
                <a href="/">
                <img width="180" alt="logo" src="@/assets/images/pandaman_logo_H_title_bg_dark_v2.png">
                </a>
            </p>
            <p>
                Copyright © 2021
                <a href="https://www.pandaman.co.nz/">PANDAMAN.CO.NZ</a>
            </p>
        </div>
    </div>
</template>



<script>
export default {
    name: 'Foot'
}
</script>
