import axios from 'axios'
import qs from 'qs'
import md5 from 'js-md5'

import config from "@/utils/config"
import { isProdEnv } from '@/utils/env.js'
import Vue from '@/main'

// MARK - Global config
// ************************************************************************
// 检测当前环境, 并初始化 API baseURL
if (isProdEnv()) {
    axios.defaults.baseURL = config.API_PROD
} else {
    axios.defaults.baseURL = config.API_DEV
}
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'


// MARK - CONSTANT
// ************************************************************************
// json格式请求头
const headerJSON = {
    "Content-Type": "application/json;charset=UTF-8"
};
// FormData格式请求头
const headerFormData = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
};


// MARK - Request 拦截器
// ************************************************************************
axios.interceptors.request.use((config) => {
    // 给请求添加请求时间
    // if (config.url.indexOf('?') !== -1) {
    //     config.url += `&t=${new Date().getTime()}`;
    // } else {
    //     config.url += `?t=${new Date().getTime()}`;
    // }
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    // config.transformRequest = [(data, headers) => {
    //     return qs.stringify(data, {
    //         allowDots: true
    //     })
    // }];

    if('post' === config.method) {
        // POST JSON数据 MD5计算, 插入headers
        config.headers['Md5'] = md5(config.data)
    }

    // `paramsSerializer` 是一个负责 `params` 序列化的函数
    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: 'repeat'
        })
    };
    return config;
}, (error) => {
    return Promise.reject(error);
});


// MARK - Response 拦截器
// ************************************************************************
axios.interceptors.response.use((response) => {
    // 👌 网络请求成功
    const { data, status } = response;
    let business_code = data.code       // 业务状态码
    let business_data = data.data       // 业务数据
    let data_md5 = data.md5             // 数据md5

    // : data数据 MD5值 校验
    let business_data_json = JSON.stringify(business_data)
    let business_data_md5 = md5(business_data_json)
    if (business_data_md5 !== data_md5) {
        Vue.$message.error( Vue.$t('hint.errorMd5') )
    }

    // 业务 status code === 0, 数据获取成功
    if (response.data.code === 0) {
        // 返回 response 给callback
        return response;
    }

    // 业务 status code !== 0
    if(response.data.code !== 0){
        // 后端处理异常, 抛出提示给客户
        switch (response.data.code) {
            default:
                Vue.$message.error( Vue.$t('hint.errorURL') );
        }
    }
}, (error) => {
    // ❌ 网络请求错误
    // 429 - 访问太快
    if (429 === error.response.status) {
        // 提醒客户访问过快
        Vue.$message.error( Vue.$t('hint.errorTooFast') )
    }
    return Promise.reject(error);
});


// MARK - GET 请求
// ************************************************************************
/**
 * @func GET请求
 *
 * @param {String} url 请求URL地址
 * @param {Object} params 请求data参数
 * @returns
 */
export function get(url, params) {
    // 判断是否含有params
    if (params) {
        url = url + "?"
    }
    // 拼接params
    for (var key in params) {
        var val = params[key];
        url = url + "&" + key + "=" + val
    }
    return new Promise((resolve, reject)=>{
        axios({
            url: url,
            method: "get",
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}


// MARK - POST 请求
// ************************************************************************
/**
 * @func POST请求
 *
 * @param {String} url 请求URL地址
 * @param {Object} params 请求data参数
 * @param {Boolean} json 是否采用JSON请求
 * @returns
 */
export function post(url, params, json=false) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "post",
            data: json ? JSON.stringify(params) : qs.stringify(params),
            headers: json? headerJSON : headerFormData
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}
