<template>
    <div class="header header-background sticky">
        <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="1"></a-col>
            <a-col :span="2">
                <a href="/">
                    <img src="@/assets/images/pandaman_logo_H_title_bg_dark_v2.png" style="max-width:150px;max-height:150px;">
                </a>
            </a-col>
            <a-col :span="10" offset="3">
                <a-input-search
                    class="container-search"
                    placeholder="热门搜索"
                    type="text"
                    size="large"
                    v-model="searchInput"
                    @keyup.enter="homeSearch()"
                    @search="homeSearch"
                >
                    <a-button type="buttton" slot="enterButton">
                        搜索
                    </a-button>
                </a-input-search>
            </a-col>
            <a-col :span="8">
            </a-col>
        </a-row>
    </div>
</template>

<script>

export default {
    name: 'Head',
    data() {
        return {
            searchInput: null,
        };
    },
    methods: {
        /**
        * @func 首页搜索响应
        */
        homeSearch() {
            this.$router.push({ name: 'List', params: { searchString: this.searchInput } });
        }
    }
}
</script>

<style scoped>

.container-search {
    padding-top: 12px;
}

</style>
